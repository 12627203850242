<template>
  <div v-if="permissions.scope === 'read:homeowner' || permissions.scope === 'read:agent,write:agent'">
    <v-container
    id="user-profile"
    fluid
    tag="section"
    >
    <base-material-card>
        <template v-slot:heading>
            <div class="display-2 font-weight-light">
                Use this section to view updates from the home.
            </div>
        </template>
    </base-material-card>
        <v-row justify="center">
                <v-carousel
                height="auto"
                >
                    <v-carousel-item
                        v-for="(image,i) in images"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                    <div v-if="image.fileType === 'pdf'">
                    <pdf
                    :src="image.url"  alt="">
                    </pdf>
                    </div>
                    <div v-else-if="image.fileType !== 'pdf'">
                    <img
                    :src="image.url"  alt=""
                    />
                    </div>
                    </v-carousel-item>
                </v-carousel>
        </v-row>
        <base-material-card
                    icon="mdi-upload"
                    title="Home Updates"
                    class="px-5 py-3"
                    >
                    <v-data-table
                        :headers="headers"
                        :items="images"
                        >

                        <template v-slot:[`item.image`]="{ item }">
                           <v-btn
                            icon
                            :href="item.url"
                            target="_blank"
                            >
                                <v-icon :href="item.url" >mdi-download</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    </base-material-card>
    </v-container>
  </div>
    <div v-else-if="permissions.scope === 'create:users,fullaccess:staff'">
    <v-container
    id="user-profile"
    fluid
    tag="section"
    >
    <base-material-card>
        <template v-slot:heading>
            <div class="display-2 font-weight-light">
                This section is to view updates to the homeowner's home.
            </div>
        </template>
    </base-material-card>
        <v-row justify="center">
            <v-col
            cols="4"
            md="4"
            class="text-right"
            >
               <v-file-input
                    accept="*"
                    label="File input"
                    v-on:change="on_file_change"
                    show-size
                ></v-file-input>

                <v-btn
                    color="success"
                    class="px-5 py-3"
                    @click="upload_File"
                    :disabled="file==null"
                >
                   Upload 
                </v-btn>
            </v-col>
          </v-row>
            <v-row justify="center">
                <v-carousel
                  height="auto"
                >
                     <v-carousel-item
                        v-for="(image,i) in images"
                        :key="i"
                        reverse-transition="fade-transition"
                        transition="fade-transition"
                    >
                    <div v-if="image.fileType === 'pdf'">
                    <pdf
                    :src="image.url"  alt="">
                    </pdf>
                    </div>
                    <div v-else-if="image.fileType !== 'pdf'">
                    <img
                    :src="image.url"  alt=""
                    />
                    </div>
                    </v-carousel-item>
                </v-carousel>
          </v-row>
          <v-row justify="center">
            <v-col
            cols="12"
            md="8"
            >
                <base-material-card
                    icon="mdi-upload"
                    title="Home Updates"
                    class="px-5 py-3"
                    >
                    <v-data-table
                        :headers="headers"
                        :items="images"
                        >

                        <template v-slot:[`item.image`]="{ item }">
                           <v-btn
                            icon
                            :href="item.url"
                            target="_blank"
                            >
                                <v-icon :href="item.url" >mdi-download</v-icon>
                            </v-btn>
                           <v-btn
                           icon
                            ><v-icon @click="deleteUpload(item)">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    </base-material-card>
            </v-col>
        </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios';
import pdf from 'vue-pdf'
export default {
    name: 'HomePhase',
    data () {
      return {
          file: null,
          home_id : localStorage.getItem("homeid"),
          headers:[
              {
                text: 'File Name',
                sortable: false,
                class:"primary--text",
                value: 'file_name',
                },
                { 
                    text: 'Download / Delete', 
                    value: 'image', 
                    align: 'end',
                    sortable: false,
                    class:"primary--text",
                },
            ],
            images: [],
      }
    },
    created () {
        this.initialize()
    },
     methods: {

        on_file_change(file){
            this.file = file;
        },
        upload_File(){
            var body ={
                "client_method_name": "put_object",
                "bucket_name": process.env.VUE_APP_CONTENT_BUCKET_NAME_URL,
                "object_name": 'documents/home-updates/' + this.home_id +'/'+ this.file.name,
                "expiration": 3600
            }

            axios
            .post(process.env.VUE_APP_GENERATE_PRESIGNED_POST_URL,body)
            .then(response => {
                
                let url  = response.data.url
                let fields  = response.data.fields

                // convert your url_fields into a FormData instance
                const data = Object.entries(fields).reduce((fd, [ key, val ]) => (fd.append(key, val), fd), new FormData())

                // now add the file
                data.append('file', this.file)
                
                const headers = { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization':'' 
                    }

                axios.post( url, data, {headers: headers} )
                .then(() => {
                    this.initialize()
                    this.file=null;
                })
                .catch(error => {
                    console.log(error)
                });

            })
            .catch(error => {
              console.log(error)
            })
        },
        deleteUpload(item){
            let data = {
                "bucket_name": process.env.VUE_APP_CONTENT_BUCKET_NAME_URL,
                "key_name": `documents/home-updates/${this.home_id}/${item.file_name}`
            }
            axios
            .delete(process.env.VUE_APP_DELETE_UPLOAD, {data: data})
            .then(response => {
                const {data} = response
                console.log(data)
                this.initialize()
            })
            .catch(error => {
                console.log(error)
            }) 
            },
        initialize(){
            var body ={
                "client_method_name": "get_object",
                "bucket_name": process.env.VUE_APP_CONTENT_BUCKET_NAME_URL,
                "prefix": "documents/home-updates/"+this.home_id+"/",
                "expiration": 3600
            }

            axios
            .post(process.env.VUE_APP_GENERATE_BULK_PRESIGNED_URL,body)
            .then(response => {
                this.images=response.data
                this.images.map((element) => {
                    let tempfileName = element
                    let fileType = tempfileName.file_name.split(".").pop();
                    element.fileType = fileType
                })
            })
            .catch(error => {
                console.log(error)
            })
        }
    },
    computed: {
    permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      }
    },
    components: {
    pdf
  }
}
</script>